// Generated by Framer (a96673f)

import { addFonts, cx, CycleVariantState, getFonts, useConstant, useIsOnFramerCanvas, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Carousel from "https://framerusercontent.com/modules/UIrMjSS6ZX89L0CsT8k6/w90zR0qzeh1mgaDSvD54/Carousel.js";
const CarouselFonts = getFonts(Carousel);

const cycleOrder = ["wRjR8L1c5"];

const serializationHash = "framer-t47Dh"

const variantClassNames = {wRjR8L1c5: "framer-v-16lkqv7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "wRjR8L1c5", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const isOnCanvas = useIsOnFramerCanvas()

const initialVariant = useConstant(() => variant)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-16lkqv7", className, classNames)} data-framer-name={"Variant 1"} initial={isOnCanvas ? variant : initialVariant } layoutDependency={layoutDependency} layoutId={"wRjR8L1c5"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}><motion.div className={"framer-h32c70-container"} layoutDependency={layoutDependency} layoutId={"EnigcKDWD-container"} transformTemplate={transformTemplate1}><Carousel align={"center"} ariaLabel={""} arrowObject={{arrowFill: "rgba(0, 0, 0, 0.2)", arrowPadding: 20, arrowRadius: 40, arrowSize: 40, showMouseControls: true}} axis borderRadius={0} fadeObject={{fadeAlpha: 0, fadeContent: false, fadeInset: 0, fadeTransition: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}, fadeWidth: 25}} gap={10} height={"100%"} id={"EnigcKDWD"} layoutId={"EnigcKDWD"} padding={10} paddingBottom={10} paddingLeft={10} paddingPerSide={false} paddingRight={10} paddingTop={10} progressObject={{dotsActiveOpacity: 1, dotsBackground: "rgba(0, 0, 0, 0.2)", dotsBlur: 4, dotsFill: "rgb(255, 255, 255)", dotsGap: 10, dotsInset: 10, dotSize: 10, dotsOpacity: 0.5, dotsPadding: 10, dotsRadius: 50, showProgressDots: false, showScrollbar: false}} sizingObject={{heightInset: 0, heightRows: 2, heightType: "auto", widthColumns: 2, widthInset: 0, widthType: "auto"}} slots={[]} snapObject={{fluid: false, snap: true, snapEdge: "center"}} style={{width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-t47Dh[data-border=\"true\"]::after, .framer-t47Dh [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-t47Dh.framer-gxp524, .framer-t47Dh .framer-gxp524 { display: block; }", ".framer-t47Dh.framer-16lkqv7 { height: 290px; overflow: hidden; position: relative; width: 1146px; }", ".framer-t47Dh .framer-h32c70-container { flex: none; height: auto; left: 0px; position: absolute; right: 0px; top: 50%; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 290
 * @framerIntrinsicWidth 1146
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Frameruh9NA7VxT: React.ComponentType<Props> = withCSS(Component, css, "framer-t47Dh") as typeof Component;
export default Frameruh9NA7VxT;

Frameruh9NA7VxT.displayName = "Course Card";

Frameruh9NA7VxT.defaultProps = {height: 290, width: 1146};

addFonts(Frameruh9NA7VxT, [...CarouselFonts])